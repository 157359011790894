import styled from "@emotion/styled";

export default function PrivacyPolicy() {
  return (
    <PrivacyPolicyContainer>
      <Logo>
        <LogoEmoji>📋</LogoEmoji>
      </Logo>
      <h1>개인정보처리방침</h1>
      <p>
        클로그(이하 ‘앱ʼ)은(는) 정보주체의 자유와 권리 보호를 위해 「개인정보
        보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고
        안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라
        정보주체에게 개인정보의 처리와 보호에 관한 절차 및 기준을 안내하고, 이와
        관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
        개인정보 처리방침을 수립·공개합니다.
      </p>
      <StyledNotice>
        <b>❗ 이 개인정보 처리방침은 2024. 8. 6부터 적용됩니다.</b>
      </StyledNotice>

      <h3>1. 개인정보의 처리 목적</h3>
      <p>
        앱은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
        변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는
        등 필요한 조치를 이행할 예정입니다.
      </p>
      <h4>1.1 홈페이지 회원가입 및 관리</h4>
      <ul>
        <li>회원 가입 의사 확인</li>
        <li>회원제 서비스 제공에 따른 본인 식별 및 인증</li>
        <li>회원 자격 유지 및 관리</li>
        <li>서비스 부정 이용 방지</li>
        <li>각종 고지 및 통지</li>
        <li>고충 처리</li>
      </ul>

      <h3>2. 처리하는 개인정보의 항목</h3>
      <p>
        앱은 서비스 제공을 위해 필요 최소한의 범위에서 개인정보를
        수집‧이용합니다.
      </p>
      <h4>2.1 정보주체의 동의를 받지 않고 처리하는 개인정보 항목</h4>
      <p>앱은 다음의 개인정보 항목을 정보주체의 동의 없이 처리하고 있습니다.</p>
      <ul>
        <li>회원 서비스 운영: 이름, 로그인, 비밀번호, 이메일</li>
        <li>서비스 이용 기록: 서비스 이용 기록</li>
      </ul>

      <h3>3. 개인정보의 처리 및 보유 기간</h3>
      <p>
        앱은 법령에 따른 개인정보 보유‧이용 기간 또는 정보주체로부터 개인정보를
        수집 시에 동의 받은 개인정보 보유‧이용 기간 내에서 개인정보를
        처리‧보유합니다. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
      </p>
      <ul>
        <li>홈페이지 회원가입 및 관리: 회원 탈퇴 시까지</li>
        <li>회원 가입 의사 확인</li>
        <li>회원제 서비스 제공에 따른 본인 식별 및 인증</li>
        <li>회원 자격 유지 및 관리</li>
        <li>서비스 부정 이용 방지</li>
        <li>각종 고지 및 통지</li>
        <li>고충 처리</li>
      </ul>

      <h3>4. 개인정보의 파기 절차 및 방법</h3>
      <p>
        앱은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게
        되었을 때에는 지체없이 해당 개인정보를 파기합니다. 정보주체로부터
        동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도
        불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당
        개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여
        보존합니다.
      </p>
      <h4>파기 절차</h4>
      <p>
        앱은 파기 사유가 발생한 개인정보를 선정하고, 앱의 개인정보 보호책임자의
        승인을 받아 개인정보를 파기합니다.
      </p>
      <h4>파기 방법</h4>
      <p>
        앱은 전자적 파일 형태로 기록‧저장된 개인정보는 기록을 재생할 수 없도록
        파기하며, 종이 문서에 기록‧저장된 개인정보는 분쇄기로 분쇄하거나
        소각하여 파기합니다.
      </p>

      <h3>5. 개인정보의 안전성 확보조치</h3>
      <p>
        앱은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다:
      </p>
      <ul>
        <li>
          관리적 조치: 내부관리계획 수립‧시행, 전담조직 운영, 정기적 직원 교육
        </li>
        <li>
          기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
          설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
        </li>
        <li>물리적 조치: 전산실, 자료보관실 등의 접근통제</li>
      </ul>

      <h3>6. 정보주체와 법정대리인의 권리·의무 및 행사방법에 관한 사항</h3>
      <p>
        정보주체는 앱에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 및 철회
        요구, 자동화된 결정에 대한 거부 또는 설명 요구 등의 권리를 행사할 수
        있습니다.
      </p>
      <p>
        정보주체는 언제든지 홈페이지 ‘내정보 {">"} 회원정보ʼ에서 개인정보를 직접
        조회·수정·삭제하거나 ‘문의하기ʼ를 통해 열람을 요청할 수 있습니다.
        정보주체는 언제든지 ‘회원탈퇴ʼ를 통해 개인정보의 수집 및 이용 동의
        철회가 가능합니다.
      </p>
      <p>
        권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여
        하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시ˮ 별지 제11호
        서식에 따른 위임장을 제출하셔야 합니다.
      </p>

      <h3>
        7. 개인정보 보호책임자의 성명 또는 개인정보 업무 담당부서 및 고충사항을
        처리하는 부서에 관한 사항
      </h3>
      <p>
        앱은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
        관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
        개인정보보호 담당부서를 지정하고 있습니다.
      </p>
      <p>개인정보보호 담당부서: 클로그</p>
      <p>연락처: clog.application@gmail.com</p>
      <p>
        정보주체는 클로그의 서비스(또는 사업)을 이용하시면서 발생한 모든
        개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보보호
        담당부서로 문의할 수 있습니다. 클로그는 정보주체의 문의에 대해 지체없이
        답변 및 처리해드릴 것입니다.
      </p>
    </PrivacyPolicyContainer>
  );
}

const PrivacyPolicyContainer = styled.div`
  padding: 20px;
  background-color: white;
  color: black;
  border-radius: 12px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: ${window.screen.width * 0.6}px;
  }
`;

const Logo = styled.div`
  display: flex;
  width: 180px;
  height: 180px;
  align-items: center;
`;

const LogoEmoji = styled.span`
  font-size: 96px;
  line-height: 1;
`;

const StyledNotice = styled.p`
  background-color: rgba(0, 0, 0, 0.05); /* 은은한 회색 배경 */
  flex: 1;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px; /* 다른 요소와의 간격을 위해 추가 */
`;
