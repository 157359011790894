import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import clogIcon from "../assets/images/clogicon.png";

export default function Home() {
  return (
    <AppContainer>
      <Logo src={clogIcon} alt="clogicon"></Logo>
      <ContentContainer>
        <Title>CLOG</Title>
        <ContactContainer>
          <b>📧 Email: clog.application@gmail.com</b>
        </ContactContainer>
        <div>
          <StyledWrapper>
            <StyledLink to="/privacy-policy">📋 개인정보처리방침</StyledLink>
          </StyledWrapper>
        </div>
      </ContentContainer>
    </AppContainer>
  );
}

const AppContainer = styled.div`
  display: flex;
  max-width: 100%;
  padding: 16px;
  background-color: white;
  color: black;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 768px) {
    max-width: ${window.screen.width * 0.6}px;
  }
`;

const Logo = styled.img`
  width: 180px;
  height: 180px;
  display: flex;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  display: flex;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContactContainer = styled.p`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 16px;
  margin: 0;
  word-wrap: break-word;
`;

const StyledWrapper = styled.p`
  display: flex;
  margin: 0;
  padding: 0;
  height: 48px;
  align-items: center;
`;

const StyledLink = styled(Link)<{ to: string }>`
  display: flex;
  flex: 1;
  padding: 8px;
  border-radius: 12px;
  height: 40px;
  align-items: center;
  text-decoration: none;
  color: black;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
